import React, { useState, useRef, useEffect } from 'react'
import { useChat } from '../hooks/useChat';
import { useSelector } from 'react-redux';
import config from '../config.json';

const  theme = {
  name:  'Clásico',
  values: {
    primary: '#FF406F',
    primaryDark: '#D13E62',
    secondary: '#FFFFFF',
    secondaryDark: '#000000'
  }
};

const Message = ({ from, id, text, theme }) => {
  return (
    <div className="message">
      <div className="msg-content">
        <span className="username">{ from }</span>
        <p className="text">{ text }</p>
      </div>
    </div>
  ) 
};


const Chat = ({ theme, socket }) => {
  const data = useSelector(state => state.data);
  const { messages } = useChat({ socket });
  const messagesRef = useRef(null);
  
  useEffect(() => {
    if(messagesRef.current){
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }  
  }, [messages]);
 
  return (
    <div className="container-fluid app-container">
      <div className="row">
        <div className="col-12 col-md-4">
            <div className="info ">
              <div className="title">
                <h1>{data.name}</h1>
              </div>
              <div className="image">
                <img src={data.logo || config.logo}
                  className="img-fluid"
                />
              </div>
              <div className="names my-3 my-md-0">
                <h2>{ data.programName || data.currentProgram }</h2>
                <h3>{ data.djName || data.currentDj }</h3>
              </div>
            </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="chat">
            <div className="messages" ref={messagesRef}>
              { messages.map((m, i) => <Message key={i} {...m} theme={theme}/>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const withTheme = (Component) =>
  (props) => <Component theme={theme.values} {...props}/>;

export default withTheme(Chat);
