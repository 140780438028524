import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../store';
          
const useChat = ({ socket }) => {
  const { messages } = useSelector(state => state.chat);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if(socket) {
        try{
          console.log('Socket connected: ', socket.connected);
          socket.on('connect', handleConnect);
          socket.on('connect_error', handleConnectError);
          socket.on('connect_failed', handleConnectError);
          socket.on('disconnect', handleDisconnect);
          socket.on('message', handleReceiveMessage);
        } catch(error){
          console.log('Error connecting to the server')
        }
      }
    })();
    return () => {
      if(socket){
        socket.off('connect', handleConnect);
        socket.off('connect_error', handleConnectError);
        socket.off('connect_failed', handleConnectError);
        socket.off('disconnect', handleDisconnect);
        socket.off('message', handleReceiveMessage);
      }
    }
  }, [socket]);

  const handleConnect = () => {
    console.log('Connecteed')
  };

  const handleConnectError = (error) => {
    console.log('Socket connect error', error);
  };
  
  const handleReceiveMessage = (data) => {
    console.log('messages in receive', messages);
    console.log('receive message', data);
    dispatch(addMessage(data));
  };

  const handleDisconnect = () => {
    console.log('socket disconnected')
  };

  const sendMsg = (msg) => {
    console.log('sending message', msg);
    socket.emit('send-msg', msg);
  };

  const handleAddMsg = (msg) => {
    dispatch(addMessage(msg)); 
    sendMsg(msg);
  };

  return {  
    messages,
    handleAddMsg,
    id: socket ? socket.id : null
  }
};

export { useChat };
