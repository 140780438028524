import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import store from './store';
import Chat from './screens/Chat';
import "bootstrap/dist/css/bootstrap.min.css"
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import config from './config.json';
import { setData } from './store';
import "./App.css";
import { socket } from './socket';

const withSocket = (Component) => (props) => <Component socket={socket} {...props}/>;

function DashRadioApp(){
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  
  useEffect(() => {
    (async () => {
      try{
       let response = await axios({
          url: config.data_url,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          }
       });
        dispatch(setData(response.data));
        if(response.data.urlLiveData){
          let ldResponse = await axios(response.data.urlLiveData);
          if(ldResponse.data){
            console.log(ldResponse.data, 'live data');
            dispatch(setData({ ...response.data, ...ldResponse.data}));
          }
        }
        console.log('data', response.data);
      } catch(error){
        console.log(error);
      }
    })();
  }, []);
  
  return (
    <Switch>
      <Route exact path="/" component={withSocket(Chat)}/>
    </Switch>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <DashRadioApp/>
      </Router>
    </Provider>

  );
}

export default App;
