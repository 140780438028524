import { createStore, combineReducers } from 'redux';

export const setAuthInfo = (username, phone) => ({
  type: ACTIONS.SET_AUTH_INFO,
  username,
  phone
});

const ACTIONS = {
  'SET_DATA': 'SET_DATA',
  'SET_MESSAGES': 'SET_MESSAGES',
  'ADD_MSG': 'ADD_MSG',
  'SET_SOCKET': 'SET_SOCKET',
  'SET_AUTH_INFO': 'SET_AUTH_INFO'
};


export const setData = (data) => 
  ({ type: ACTIONS.SET_DATA, data });

export const setMessages = (messages) => 
  ({ type: ACTIONS.SET_MESSAGES, messages });

export const addMessage = (message) => 
  ({ type: ACTIONS.ADD_MSG, message });

const dataReducer = (state = {}, action) => {
  switch(action.type){
    case ACTIONS.SET_DATA:
      return {
        ...action.data
      }
    default: 
      return state;
  }
};

const userReducer = (state = { username: '', phone: ''}, action) => {
  switch(action.type){
    case ACTIONS.SET_AUTH_INFO:  
      return {
        ...state,
        username: action.username,
        phone: action.phone
      }
    default:  
     return state;
  }
};

const chatReducer = (state = { messages: [
  { from: 'System',  text: 'No hay mensajes aun', id: 1},

], socket: null }, action) => {
  switch(action.type){
    case ACTIONS.SET_MESSAGES:
      return {
        ...state,
        messages: action.messages
      }
    case ACTIONS.ADD_MSG:
      return {
        ...state,
        messages: [...state.messages, action.message]
      }
    default: 
      return state;
  }
};

export default createStore(combineReducers({
  data: dataReducer,
  chat: chatReducer,
  user: userReducer
}));


